"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = chartsReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {};

function chartsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.CHARTS.DATA.MERGE:
      return mergeData(state, action);
      return Object.assign({}, state, {
        [action.listingId]: merge(state[action.listingId], action.data)
      });

    case T.CHARTS.DATA.SET:
      return setData(state, action);

    case T.CHARTS.FTC.BEFORE.OK:
    case T.CHARTS.FTC.BETWEEN.OK:
      return mergeData(state, _objectSpread({}, action.result));

    default:
      return state;
  }
}

function calculateAVGs(arr) {
  var EMA20Mult = 0.095238095;
  var sum12 = 0;
  var sum20 = 0;
  var sum26 = 0;
  var sum50 = 0;
  var sum125 = 0;
  var sum200 = 0;
  var macdSum = 0;

  for (var i = 0; i < arr.length; i++) {
    var today = arr[i];
    sum12 += today.close;
    sum20 += today.close;
    sum26 += today.close;
    sum50 += today.close;
    sum125 += today.close;
    sum200 += today.close;
    if (i > 11) sum12 -= arr[i - 12].close;
    if (i > 19) sum20 -= arr[i - 20].close;
    if (i > 25) sum26 -= arr[i - 26].close;
    if (i > 49) sum50 -= arr[i - 50].close;
    if (i > 124) sum125 -= arr[i - 125].close;
    if (i > 199) sum200 -= arr[i - 200].close;

    if (i > 10) {
      var prevDay = arr[i - 1];
      today.sma12 = sum12 / 12; //ema 12

      if (i > 11) today.ema12 = (today.close - prevDay.ema12) * 0.153846154 + prevDay.ema12;else today.ema12 = today.sma12;

      if (i > 18) {
        //ema 20
        today.sma20 = sum20 / 20;
        if (i > 19) today.ema20 = (today.close - prevDay.ema20) * EMA20Mult + prevDay.ema20;else today.ema20 = today.sma20;

        if (i > 24) {
          today.sma26 = sum26 / 26;
          if (i > 25) today.ema26 = (today.close - prevDay.ema26) * 0.074074074 + prevDay.ema26;else today.ema26 = today.sma26;
          today.macd = today.ema12 - today.ema26;
          if (i < 34) macdSum += today.macd;else if (i == 34) today.macdSignal = macdSum / 9;else if (i > 34) {
            today.macdSignal = (today.macd - prevDay.macdSignal) * 0.2 + prevDay.macdSignal;
            today.macdHisto = today.macd - today.macdSignal;
          }

          if (i > 48) {
            today.sma50 = sum50 / 50; //ema 50

            if (i > 49) today.ema50 = (today.close - prevDay.ema50) * 0.039215686 + prevDay.ema50;else today.ema50 = today.sma50;

            if (i > 123) {
              today.sma125 = sum125 / 125;
              if (i > 198) today.sma200 = sum200 / 200;
            }
          }
        }
      }
    }

    if (i > 19) {
      var wma = 0;

      for (var j = 0; j < 20; j++) {
        wma += arr[i - j].close * ((20 - j) * EMA20Mult);
      }

      today.wma20 = wma / 20;
    }
  }

  return arr;
}
/**
 * Merges existing data for listing id with new data from action.
 * No duplicates of same data point id exists
 * @param {Object} state - current state of reducer
 * @param {Object} action - action object with data field, which is of type {Array}
 * @return {Object} - new state
 */


function mergeData(state, action) {
  return Object.assign({}, state, {
    [action.listingId]: calculateAVGs(merge(state[action.listingId], action.data))
  });
}
/**
 * Replaces chart data for a listing id with another data.
 * @param {Object} state
 * @param {Object} action
 * @return {Object} new state
 */


function setData(state, action) {
  return Object.assign({}, state, {
    [action.listingId]: action.data
  });
}
/**
 * Merges two arrays into one, sorted by timestamp field.
 * Returns array without duplicates.
 * Seems to be quite fast (2 arrays with 100,000 items each - 140ms)
 * @param {Object[]} left
 * @param {Object[]} right
 * @return
 */


function merge() {
  var left = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var right = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  left = left.sort(comparator);
  right = right.sort(comparator);
  var merged = [];
  var lPos = 0,
      rPos = 0;

  while (lPos < left.length && rPos < right.length) {
    var diff = comparator(left[lPos], right[rPos]);
    var tbi = void 0; //to be inserted

    if (diff <= 0) {
      tbi = left[lPos];
      lPos++;
    } else if (diff > 0) {
      tbi = right[rPos];
      rPos++;
    }

    if (!orderedContainsId(merged, tbi)) {
      merged.push(tbi);
    }
  } //merge the rest


  while (lPos < left.length) {
    if (!orderedContainsId(merged, left[lPos])) {
      merged.push(left[lPos]);
    }

    lPos++;
  }

  while (rPos < right.length) {
    if (!orderedContainsId(merged, right[rPos])) {
      merged.push(right[rPos]);
    }

    rPos++;
  }

  return merged;
}
/**
 * Expects array to be ordered in order to validate correctly!!!
 * @param {Object[]} array - array of data points
 * @param {Object} - data point
 * @return {boolean}
 */


function orderedContainsId(array, dp) {
  var {
    id,
    timestamp
  } = dp; //start from end

  for (var i = array.length - 1; i >= 0; i--) {
    var each = array[i];
    /*
    * If code reached index at which timestamp is smaller than data point we are
    * comparing, don't look any further
    */

    if (each.timestamp < timestamp) {
      return false;
    }

    if (each.id == id) {
      return true;
    }
  }

  return false;
}
/**
 * Compares timestamps in objects.
 * Causes ascending timestamp sorting.
 * @param {Object} a - object with field timestamp of numerical value
 * @param {Object} b - object with field timestamp of numerical value
 * @return {number}
 */


function comparator(a, b) {
  return a.timestamp - b.timestamp;
}