"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitContent = exports.fetchSingle = exports.fetchAttachments = exports.fetchComments = exports.fetchEntityTimeline = exports.fetchUserTimeline = exports.fetchCompanyTimeline = exports.fetchMyTimeline = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchMyTimeline = (_ref) => {
  var {
    timestamp = "",
    skip = 0,
    direction = -1,
    postType = "news,userpost,usercomment,article"
  } = _ref;
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    types: [T.FTC_MY_CHRONO_RQ, T.FTC_MY_CHRONO_OK, T.FTC_MY_CHRONO_FAI],
    promise: () => _apiSdk.v2API.Timeline.getMyTimeline({
      timestamp,
      skip,
      direction,
      limit: 20,
      postType
    })
  });
};

exports.fetchMyTimeline = fetchMyTimeline;

var fetchCompanyTimeline = (companyId, _ref2) => {
  var {
    timestamp = "",
    skip = 0,
    direction = -1,
    postType = "article,news,userpost,companySquawk"
  } = _ref2;
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    entityId: companyId,
    types: [T.FTC_CHRONO_RQ, T.FTC_CHRONO_OK, T.FTC_CHRONO_FAI],
    promise: () => _apiSdk.v2API.Timeline.getCompanyTimeline(companyId, {
      timestamp,
      skip,
      direction,
      limit: 20,
      postType
    })
  });
};

exports.fetchCompanyTimeline = fetchCompanyTimeline;

var fetchUserTimeline = (userId, _ref3) => {
  var {
    timestamp = "",
    skip = 0,
    direction = -1,
    postType = "userpost,usercomment,article,companySquawk"
  } = _ref3;
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    entityId: userId,
    types: [T.FTC_CHRONO_RQ, T.FTC_CHRONO_OK, T.FTC_CHRONO_FAI],
    promise: () => _apiSdk.v2API.Timeline.getUserTimeline(userId, {
      timestamp,
      skip,
      direction,
      limit: 20,
      postType
    })
  });
};

exports.fetchUserTimeline = fetchUserTimeline;

var fetchEntityTimeline = (entityId, _ref4) => {
  var {
    timestamp = "",
    skip = 0,
    direction = -1,
    postType = "userpost,news,article,companySquawk"
  } = _ref4;
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    entityId,
    postType,
    types: [T.FTC_CHRONO_RQ, T.FTC_CHRONO_OK, T.FTC_CHRONO_FAI],
    promise: () => _apiSdk.v2API.Timeline.getEntityTimeline(entityId, {
      timestamp,
      direction,
      skip,
      postType
    })
  });
};

exports.fetchEntityTimeline = fetchEntityTimeline;

var fetchComments = entityId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  entityId,
  types: [T.FTC_CHRONO_RQ, T.FTC_CHRONO_OK, T.FTC_CHRONO_FAI],
  promise: () => _apiSdk.v2API.Timeline.getComments(entityId)
});

exports.fetchComments = fetchComments;

var fetchAttachments = postId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  entityId: postId,
  types: [T.FTC_CHRONO_RQ, T.FTC_CHRONO_OK, T.FTC_CHRONO_FAI],
  promise: () => _apiSdk.v2API.Timeline.getEntityTimeline(postId, {
    postType: "userattachment"
  })
});

exports.fetchAttachments = fetchAttachments;

var fetchSingle = entityId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  entityId,
  types: [T.FTC_CHRONO_SINGLE_RQ, T.FTC_CHRONO_SINGLE_OK, T.FTC_CHRONO_SINGLE_FAI],
  promise: () => _apiSdk.v2API.Timeline.getItem(entityId)
});

exports.fetchSingle = fetchSingle;

var submitContent = function (content) {
  var targets = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (0, _thunk.thunkCreatorWithoutConcurrency)({
    types: [T.POST_TO_CHRONO_RQ, T.POST_TO_CHRONO_OK, T.POST_TO_CHRONO_FAI],
    promise: () => _apiSdk.v2API.Timeline.createEntry(content, targets, options)
  });
};

exports.submitContent = submitContent;