"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _apiSdk = require("@voxmarkets/api-sdk");

var _default = {
  /**
   * @param  {string} publicHost : The public host of legacy apis .
   * @param  {string} apiHost : The host of the modern loopback based apis
   * @param  {string} graphqlHost : The host of the graphql server.
   */
  set: (publicHost, apiHost) => {
    _apiSdk.Config.set("PUBLIC_HOST", publicHost);

    _apiSdk.Config.set("API_HOST", apiHost);
  }
};
exports.default = _default;