"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getByCompanyId = void 0;

var types = _interopRequireWildcard(require("../../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var getByCompanyId = companyId => (0, _thunk.thunkCreatorWithoutConcurrency)({
  companyId,
  types: [types.ADV_CPNY_KEYDATES_GET_LIST_RQ, types.ADV_CPNY_KEYDATES_GET_LIST_OK, types.ADV_CPNY_KEYDATES_GET_LIST_FAI],
  promise: () => _apiSdk.v2API.AdvCompanies.getKeyDatesByCompanyId(companyId)
});

exports.getByCompanyId = getByCompanyId;