"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = chronologyReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _Utils = _interopRequireDefault(require("../Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  timelines: {},
  posts: {},
  me: [],
  loading: []
};

var mergeTimelines = function () {
  var currentTimeline = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var inboundTimeline = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // gets the list of postIds for the user's timeline as we have it already
  var postIdList = currentTimeline.map(post => post.id); // filter the inbound posts to exclude any that we have already

  var freshPosts = inboundTimeline.filter(post => postIdList.indexOf(post.id) === -1);
  return [...currentTimeline, ...freshPosts];
};

function chronologyReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.FTC_MY_CHRONO_RQ:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: [...state.loading, "me"]
      });

    case T.FTC_CHRONO_RQ:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: [...state.loading, action.entityId]
      });

    case T.FTC_MY_CHRONO_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        me: mergeTimelines(state.me, action.result.timeline),
        loading: state.loading.filter(i => i !== "me")
      });

    case T.FTC_CHRONO_SINGLE_OK:
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: Object.assign({}, state.posts, {
          [action.entityId]: action.result
        }),
        loading: state.loading.filter(i => i !== action.entityId)
      });

    case T.FTC_CHRONO_OK:
      // get the existing collection of timeline items for this company
      var existingTimeline = state.timelines.hasOwnProperty(action.entityId) ? state.timelines[action.entityId] : []; // merge the new items

      return _objectSpread(_objectSpread({}, state), {}, {
        timelines: _objectSpread(_objectSpread({}, state.timelines), {}, {
          [action.entityId]: mergeTimelines(existingTimeline, action.result.timeline)
        }),
        loading: state.loading.filter(i => i !== action.entityId)
      });

    default:
      return state;
  }
}