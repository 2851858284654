"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = authenticationReducer;

var T = _interopRequireWildcard(require("../actionTypes.js"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  loggedIn: false,
  loggingIn: false,
  username: null,
  remember: true,
  error: null
};

function authenticationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.RQ:
    case T.AUTH.WORDPRESS.LOGIN.COOKIE.RQ:
      return onLoginBegin(state, action);

    case T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.OK:
    case T.AUTH.WORDPRESS.LOGIN.COOKIE.OK:
      return onWPLoginSuccess(state, action);

    case T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.FAI:
    case T.AUTH.WORDPRESS.LOGIN.COOKIE.FAI:
      return onLoginFailure(state, action);

    case T.CLEAR:
      return initialState;

    default:
      return state;
  }
}

function onLoginBegin(state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loggingIn: true,
    loggedIn: false,
    error: null
  });
}

function onWPLoginSuccess(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loggedIn: true,
    loggingIn: false,
    remember: action.result.remember,
    error: null,
    jwt: action.result["vox-jwt"],
    wpCookie: action.result.cookie
  });
}

function onLoginFailure(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loggingIn: false,
    loggedIn: false,
    error: action.error
  });
}