"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 *
 * @param {string} digit - something that can be parsed into float, can contain dashes and commas
 * @return {number}
 */
class Utils {
  /**
   * Adds concatinates two arrays with elements from "extra" array which don't
   * exist in "origin" array added to the beginning.
   */
  static uniquePrependToArray(origin, extra) {
    if (origin && typeof origin[Symbol.iterator] == "function") {
      if (extra && typeof extra[Symbol.iterator] == "function") {
        var originSet = new Set(origin);
        var newElems = [...new Set(extra)].filter(each => !originSet.has(each));
        return [...newElems, ...origin];
      } else {
        return [...new Set(origin)];
      }
    } else if (Array.isArray(extra)) {
      return [...new Set(extra)];
    }

    return [];
  }
  /**
   * Adds concatinates two arrays with elements from "extra" array which don't
   * exist in "origin" array added to the end.
   */


  static uniqueAppendToArray(origin, extra) {
    if (origin && typeof origin[Symbol.iterator] == "function") {
      if (extra && typeof extra[Symbol.iterator] == "function") {
        var originSet = new Set(origin);
        var newElems = [...new Set(extra)].filter(each => !originSet.has(each));
        return [...origin, ...newElems];
      } else {
        return [...new Set(origin)];
      }
    } else if (Array.isArray(extra)) {
      return [...new Set(extra)];
    }

    return [];
  }
  /**
   * Function for removing elements from all arrays contained in an object.
   * Used by company and user timelines. Where structure is:
   * {
   *   user1: [squawk1Id, squawk2Id, etc.]
   * }
   */


  static filterFromAll() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var toRemove = arguments.length > 1 ? arguments[1] : undefined;
    var keys = Object.keys(state);

    if (keys.length) {
      var newState = _objectSpread({}, state);

      keys.forEach(id => {
        if (Array.isArray(state[id])) {
          newState[id] = state[id].filter(value => value != toRemove);
        }
      });
      return newState;
    }

    return state;
  }
  /**
   * Makes new set containing elements from setA, with elements from setB added
   * to on the beginning
   */


  static prependToSet(setA, setB) {
    if (setB && typeof setB[Symbol.iterator] == "function") {
      var joined = new Set(setB);

      if (setA && typeof setA.forEach == "function") {
        setA.forEach(elem => {
          joined.add(elem);
        });
      }

      return joined;
    } else if (setA) {
      if (setA.constructor == Set) {
        return setA;
      } else if (typeof setA[Symbol.iterator] == "function") {
        return new Set(setA);
      }
    }

    return new Set();
  }
  /**
   * Makes new set containing elements from setA, with elements from setB added
   * to end.
   * If B is not iterable, but A is, it returns original A
   */


  static appendToSet(setA, setB) {
    if (setA && typeof setA[Symbol.iterator] == "function") {
      var joined = new Set(setA);

      if (setB && typeof setB.forEach == "function") {
        setB.forEach(elem => {
          joined.add(elem);
        });
      }

      return joined;
    } else if (setB) {
      if (setB.constructor == Set) {
        return setB;
      } else if (typeof setB[Symbol.iterator] == "function") {
        return new Set(setB);
      }
    }

    return new Set();
  }
  /**
   * Deletes item for every set contained in the object
   */


  static deleteFromAll(state, toRemove) {
    var keys = Object.keys(state);

    if (keys.length) {
      var newState = _objectSpread({}, state);

      keys.forEach(id => {
        if (state[id] && state[id].constructor == Set) {
          newState[id] = new Set(state[id]);
          newState[id].delete(toRemove);
        }
      });
      return newState;
    }

    return state;
  }
  /**
   * Returns array without duplicates
   * @param {Array} - array to filter
   * @return {Array}
   */


  static uniquify(array) {
    return Array.from(new Set(array));
  }

  static setAsPending(state, ids) {
    if (Array.isArray(ids)) {
      var newState = _objectSpread({}, state);

      ids.forEach(id => {
        newState[id] = _objectSpread(_objectSpread({}, state[id]), {}, {
          pending: true
        });
      });
      return newState;
    }

    return state;
  }

  static upsertOne(state, id, data) {
    var newState = _objectSpread(_objectSpread({}, state), {}, {
      [id]: _objectSpread(_objectSpread({}, state[id]), data)
    });

    delete newState.pending;
    return newState;
  }
  /**
   * Updates the state with newly received one. By default merges shallowly,
   * and sets pending to falsey (removes it).
   * In some cases where we need deeper merging, but not absolutely deep -
   * it utilises functionality of "deepMerge".
   */


  static upsertMany() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var toMerge = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var keys = Object.keys(toMerge);

    if (keys.length) {
      var newState = _objectSpread({}, state);

      keys.forEach(key => {
        if (depth) {
          newState[key] = Utils.deepMerge(state[key], toMerge[key], depth);
        } else {
          newState[key] = _objectSpread(_objectSpread({}, state[key]), toMerge[key]);
        }

        delete newState[key].pending;
      });
      return newState;
    }

    return state;
  }
  /**
   * Merges objects together with mering up to desired depth. Beyond depth,
   * the properties are replaced with new ones.
   */


  static deepMerge() {
    var origin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    if (extra.constructor == Object) {
      if (depth) {
        var keys = Object.keys(extra);

        var merged = _objectSpread({}, origin);

        keys.forEach(key => {
          merged[key] = Utils.deepMerge(origin[key], extra[key], depth - 1);
        });
        return merged;
      } else {
        return _objectSpread(_objectSpread({}, origin), extra);
      }
    }

    return extra;
  }
  /**
   * Upserts only snapshots which at least one value changed.
   * @return {Object} new state
   */


  static upsertChanged(origin, extra) {
    var onlyChanged = {};

    for (var listingId in extra) {
      if (Utils.valuesDiffer(origin[listingId], extra[listingId])) {
        onlyChanged[listingId] = extra[listingId];
      }
    }

    return Utils.upsertMany(origin, onlyChanged);
  }
  /**
   * Compares if any of the values in object A would be modified by object B
   * @return {boolean}
   */


  static valuesDiffer(origin, extra) {
    if (origin) {
      for (var key in extra) {
        var currentValue = origin[key];
        if (currentValue == null) return true;
        var targetValue = extra[key];
        if (isNaN(targetValue)) continue;
        if (currentValue != targetValue) return true;
      }

      return false;
    }

    return true;
  }

}

exports.default = Utils;