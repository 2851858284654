"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**

   usage:
   const batchedCall = batched( 
       v2API.methodThatTakesIds, 
       ( response, promisesById ) => {
           Object.values( response ).forEach( value => pomisesById.resolve( value ))l
       });
   );

**/
var defaultProcessFn = (response, promisesById) => {
  Object.values(response).forEach(value => promisesById.resolve(value));
};

var _default = function _default(APICall) {
  var process = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultProcessFn;
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 25;
  var keepBatching;
  var ids = {};

  var batchedCall = () => {
    // clone & flush the ids queue
    var queue = _objectSpread({}, ids);

    ids = {};
    APICall(Object.keys(queue)).then(resp => {
      console.log("batched request ".concat(Object.keys(queue).length, " ids"));
      process(resp, queue);
    });
  };

  return function (id) {
    // 1. create a Promise and keep references to
    // its resolve and reject in the IDs queue
    var promise = new Promise((resolve, reject) => {
      ids[id] = {
        resolve,
        reject
      };
    }); // 2. reset the timer

    clearTimeout(keepBatching); // 3. set new timer; the APICall and callback will be executed once the calls stop coming

    if (Object.keys(ids).length >= limit) {
      batchedCall();
    } else {
      keepBatching = setTimeout(batchedCall, 100);
    } // 3. return the promise for a single id


    return promise;
  };
};

exports.default = _default;