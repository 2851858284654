"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logout = logout;
exports.loginWithWPCookieV2 = exports.loginWithWPCookie = exports.loginWithWPCredentials = exports.dispatchIfNotPending = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

var _jwtDecode = _interopRequireDefault(require("jwt-decode"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var dispatchIfNotPending = action => {
  return (dispatch, getState) => {
    var {
      loggingIn
    } = getState().authentication;

    if (!loggingIn) {
      return dispatch(action);
    }

    return new Promise(res => res({}));
  };
};

exports.dispatchIfNotPending = dispatchIfNotPending;

function logout() {
  return dispatch => {
    _apiSdk.Authentication.logout();

    return dispatch({
      type: T.CLEAR
    });
  };
}

var loginWithWPCredentials = function (login, password) {
  var remember = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return (dispatch, getState) => {
    var {
      loggingIn
    } = getState().authentication;

    if (!loggingIn) {
      dispatch((0, _thunk.thunkCreator)({
        types: [T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.RQ, T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.OK, T.AUTH.WORDPRESS.LOGIN.CREDENTIALS.FAI],
        promise: _apiSdk.Authentication.loginWithWPCredentials(login, password, remember).then(data => _objectSpread(_objectSpread({}, data), {}, {
          remember
        }))
      }));
    }
  };
};

exports.loginWithWPCredentials = loginWithWPCredentials;

var loginWithWPCookie = wpCookie => (dispatch, getState) => {
  var {
    loggingIn
  } = getState().authentication;

  if (!loggingIn) {
    dispatch((0, _thunk.thunkCreator)({
      types: [T.AUTH.WORDPRESS.LOGIN.COOKIE.RQ, T.AUTH.WORDPRESS.LOGIN.COOKIE.OK, T.AUTH.WORDPRESS.LOGIN.COOKIE.FAI],
      promise: _apiSdk.Authentication.authenticateWithWPCookie(wpCookie).then(result => {
        _apiSdk.legacyAPI.Users.getBasicData((0, _jwtDecode.default)(result["vox-jwt"]).userId).then(data => {
          dispatch({
            type: T.ADD_USER_DATA_MULTIPLE,
            userData: {
              name: data.name,
              email: data.email,
              id: data.id,
              login: data.user_login,
              avatarURI: data.avatar
            }
          });
        });

        return result;
      })
    }));
  }
}; // V2
// + return promise
// + catch error
// + do not fetch user data


exports.loginWithWPCookie = loginWithWPCookie;

var loginWithWPCookieV2 = wpCookie => dispatch => {
  dispatch({
    type: T.AUTH.WORDPRESS.LOGIN.COOKIE.RQ
  });
  return _apiSdk.Authentication.authenticateWithWPCookie(wpCookie).then(result => {
    return dispatch({
      type: T.AUTH.WORDPRESS.LOGIN.COOKIE.OK,
      result
    });
  }).catch(error => {
    console.log(error);
    return dispatch({
      type: T.AUTH.WORDPRESS.LOGIN.COOKIE.FAI,
      error
    });
  });
};

exports.loginWithWPCookieV2 = loginWithWPCookieV2;