"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setData = setData;
exports.mergeData = mergeData;
exports.fetchBetween = exports.fetchBefore = void 0;

var T = _interopRequireWildcard(require("../actionTypes.js"));

var _thunk = require("../thunk");

var _apiSdk = require("@voxmarkets/api-sdk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function setData(listingId, data) {
  return {
    type: T.CHARTS.DATA.SET,
    data: data,
    listingId: listingId
  };
}

function mergeData(listingId, data) {
  return {
    type: T.CHARTS.DATA.MERGE,
    data: data,
    listingId: listingId
  };
}
/**
 * @param {before} timestamp - fetched data points will be older than this
 */


var fetchBefore = function (listingId) {
  var before = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Math.floor(Date.now() / 1000);
  var limit = arguments.length > 2 ? arguments[2] : undefined;
  return (0, _thunk.thunkCreator)({
    types: [T.CHARTS.FTC.BEFORE.RQ, T.CHARTS.FTC.BEFORE.OK, T.CHARTS.FTC.BEFORE.FAI],
    promise: _apiSdk.v2API.Charts.getByListingOT(listingId, before, limit).then(data => ({
      data,
      listingId
    }))
  });
};

exports.fetchBefore = fetchBefore;

var fetchBetween = function (listingId) {
  var start = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Math.floor(Date.now() / 1000 - 604800);
  var end = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Math.floor(Date.now() / 1000);
  return (0, _thunk.thunkCreator)({
    types: [T.CHARTS.FTC.BETWEEN.RQ, T.CHARTS.FTC.BETWEEN.OK, T.CHARTS.FTC.BETWEEN.FAI],
    promise: _apiSdk.v2API.Charts.getByListingTimeWindow(listingId, start, end).then(data => ({
      data,
      listingId
    }))
  });
};

exports.fetchBetween = fetchBetween;