"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getByIds = exports.getExchangeByFactsetCode = exports.getList = void 0;

var types = _interopRequireWildcard(require("../../actionTypes.js"));

var _apiSdk = require("@voxmarkets/api-sdk");

var _thunk = require("../../thunk");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var getList = filter => (0, _thunk.thunkCreatorWithoutConcurrency)({
  filter,
  types: [types.ADV_CPNY_EXCHANGES_GET_LIST_RQ, types.ADV_CPNY_EXCHANGES_GET_LIST_OK, types.ADV_CPNY_EXCHANGES_GET_LIST_FAI],
  promise: () => _apiSdk.v2API.AdvCompanies.getExchanges(filter)
});

exports.getList = getList;

var getExchangeByFactsetCode = function () {
  var factSetCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return (dispatch, getState) => {
    var state = getState();
    var existing = state && state.advCompanies ? Object.values(state.advCompanies.exchanges).find(exchange => exchange.exchangeFactSetCode === factSetCode.toUpperCase()) : null;

    if (existing) {
      return Promise.resolve({
        result: existing
      });
    }

    var filter = {
      where: {
        exchangeFactSetCode: factSetCode.toUpperCase()
      }
    };
    return dispatch(getList(filter));
  };
};

exports.getExchangeByFactsetCode = getExchangeByFactsetCode;

var getByIds = ids => (dispatch, getState) => {
  var state = getState();
  var existing = state && state.advCompanies ? state.advCompanies.exchanges : {};
  var missing = ids.filter(id => !existing.hasOwnProperty(id));

  if (missing.length === 0) {
    return Promise.resolve({
      result: [...ids.map(id => existing[id])]
    });
  }

  return dispatch(getList({
    where: {
      id: {
        inq: missing
      }
    }
  })).then(resp => {
    return _objectSpread(_objectSpread({}, resp), {}, {
      result: [...resp.result, ...ids.filter(id => !missing.includes(id)).map(id => existing[id])]
    });
  });
};

exports.getByIds = getByIds;